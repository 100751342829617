import Posts from "components/Posts";
import Users from "components/Users";
import Nodes from "components/Nodes";
import Withdraw from "components/Withdraw";

const routes = [
  {
    path: "/",
    name: "Posts",
    title: "Posts Management",
    component: Posts,
  },
  {
    path: "/users",
    name: "Users",
    title: "Users Management",
    component: Users,
  },
  {
    path: "/nodes",
    name: "Nodes",
    title: "Nodes Management",
    component: Nodes,
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    title: "Withdraw Review",
    component: Withdraw,
  },
];

export default routes;
