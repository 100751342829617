import React, { useState } from "react";
import { Button, Table, Pagination } from "semantic-ui-react";
import { withdrawStatusNameMap } from "constant";
import TableInfo from "components/TableInfo";
import dayjs from "dayjs";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import CompleteModal from "./CompleteModal";

interface WithdrawInterface {
  createdAt: number;
  coin: string;
  targetAddress: string;
  amount: string;
  charge: string;
  status: "completed" | "pending";
  _id: string;
}

const DEFAULT_PAGE_SIZE = 10;

export default function Withdraw(): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);
  const [
    currentWithdraw,
    setCurrentWithdraw,
  ] = useState<WithdrawInterface | null>(null);
  const [search, setSearch] = useState<{
    page: number;
    page_size: number;
  }>({
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
  });

  const { isLoading, data, refetch } = useQuery(["users", search], async () => {
    const { data } = await axios.get("withdraw/adminlist");
    return data;
  });

  const completeMutation = useMutation(
    (data) => axios.post(`/withdraw/${currentWithdraw?._id}/setcomplete`, data),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(false);
      },
    }
  );

  const page = data?.page;
  const total = Math.ceil(data?.total / data?.pageSize);

  return (
    <>
      {isLoading ? (
        <TableInfo content="Loading" />
      ) : !data || !data.items || data.items.length === 0 ? (
        <TableInfo content="No data" />
      ) : (
        <>
          <Table basic unstackable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>PostDate</Table.HeaderCell>
                <Table.HeaderCell>Asset</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Fee</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.items.map((item: WithdrawInterface, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    {dayjs(item.createdAt).format("YYYY/MM/DD HH:mm")}
                  </Table.Cell>
                  <Table.Cell>{item.coin}</Table.Cell>
                  <Table.Cell>{item.targetAddress}</Table.Cell>
                  <Table.Cell>{item.amount}</Table.Cell>
                  <Table.Cell>{item.charge}</Table.Cell>
                  <Table.Cell>
                    {withdrawStatusNameMap[item.status] ?? item.status}
                  </Table.Cell>
                  <Table.Cell>
                    {item.status === "pending" && (
                      <Button
                        onClick={() => {
                          setCurrentWithdraw(item);
                          setModalOpen(true);
                        }}
                      >
                        Complete
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <div className="paginationWrapper">
            <div style={{ marginLeft: "1rem" }}>
              当前共有 {data?.items?.length ?? 0} 个提现申请，
              {
                data?.items?.filter(
                  (item: WithdrawInterface) => item.status === "pending"
                )?.length
              }{" "}
              个待执行
            </div>
            <Pagination
              activePage={page}
              totalPages={total}
              onPageChange={(e, { activePage }) =>
                setSearch({ ...search, page: Number(activePage) })
              }
            />
          </div>
        </>
      )}
      <CompleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mutation={completeMutation}
      />
    </>
  );
}
