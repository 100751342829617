import _ from "lodash";

export enum usersModalEnum {
  None,
  Level,
  Points,
  Balance,
}

export const levelOptions = _.range(12).map((item) => {
  return {
    key: item.toString(),
    value: item,
    text: item.toString(),
  };
});

const symbols = ["KSM", "DOT", "ROC", "αOSN", "βOSN"];

export const symbolOptions = symbols.map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const withdrawSymbols = ["KSM", "DOT", "ROC"];

export const withdrawSymbolOptions = withdrawSymbols.map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const withdrawStatusNameMap = {
  completed: "已完成",
  pending: "待执行",
};

export const withdrawStatusOptions = [
  {
    key: 1,
    value: 1,
    text: "待审核",
  },
  {
    key: 2,
    value: 2,
    text: "已完成",
  },
];
