import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { Input } from "components/Form";

export default function CompleteModal({
  modalOpen,
  setModalOpen,
  mutation,
}: {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any;
}): JSX.Element {
  return (
    <Modal size="mini" open={modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Header>Complete</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            blockHeight: "",
            extrinsicIndex: "",
          }}
          validate={(values) => {
            const errors = {} as {
              blockHeight: string;
              extrinsicIndex: string;
            };
            if (!values.blockHeight) {
              errors.blockHeight = "Required";
              errors.extrinsicIndex = "Required";
            }
            return errors;
          }}
          onSubmit={(values) => {
            mutation.mutate({
              blockHeight: values.blockHeight,
              extrinsicIndex: values.extrinsicIndex,
            });
          }}
        >
          {(formikProps) => (
            <Form className="modalForm">
              <Input name="blockHeight" placeholder="blockHeight" />
              <Input name="extrinsicIndex" placeholder="extrinsicIndex" />
              <div style={{ display: "flex" }}>
                <Button
                  type="button"
                  onClick={() => {
                    formikProps.submitForm();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
